import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog as MatDialog } from '@angular/material/dialog';
import { MatSnackBar as MatSnackBar } from '@angular/material/snack-bar';
import { TicketDataService } from '../ticket-data.service';
import { Ticket } from '../ticketData';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-author',
  templateUrl: './author.component.html',
  styleUrls: ['./author.component.scss']
})

export class AuthorComponent implements OnInit {

  titles = [
    { viewValue: 'Dr.' },
    { viewValue: 'Dr. Dr.' },
    { viewValue: 'Prof.' },
    { viewValue: 'Prof. Dr.'},
    { viewValue: 'Prof. Prof.' }
  ];

  year: number = new Date().getFullYear();
  // formGroup with formControls
  ticketDataForm = new UntypedFormGroup({
    titleTypeControl: new UntypedFormControl(''),
    genderFormControl: new UntypedFormControl('', Validators.required),
    detailsFormControl: new UntypedFormControl(''),
    emailFormControl: new UntypedFormControl('', [Validators.required, Validators.email]),
    fullNameFormControl: new UntypedFormControl('', Validators.required),
    dataAgreedFormControl: new UntypedFormControl('', Validators.required)
  });
  param: { lang: string; brand: 'vcg'; requestType: string; info: any };
  title = 'Customer Support Center';
  toolbar_img = 'https://cdn4.vogel.de/logos/1009/logo.svg';
  ticketDataValidation: boolean = false;
  dataPolicyValidation: boolean = true;
  company: string;
  showProgress = false;
  formSelectedBrand;
  showZUA = true;
  ticketData: Ticket;
  ticketId;
  constructor(
    private dataService: TicketDataService,
    public translate: TranslateService,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private readonly activatedRoute: ActivatedRoute
  ) { }
  ngOnInit() {
    this.company = this.activatedRoute.snapshot.params["company"]
    this.translate.setDefaultLang('de');
  }

  // if form submit
  submitTicketData(ticketData: any): void {
    if (this.ticketDataForm.valid) {
      window.scroll(0, 0);
      this.showProgress = true;
      ticketData = [{
        title: this.ticketDataForm.get('titleTypeControl').value,
        gender: this.ticketDataForm.get('genderFormControl').value,
        details: this.ticketDataForm.get('detailsFormControl').value,
        email: this.ticketDataForm.get('emailFormControl').value,
        fullName: this.ticketDataForm.get('fullNameFormControl').value,
        dataAgreement: this.ticketDataForm.get('dataAgreedFormControl').value,
        requestType: 'aa',
        company: this.company
        //brand: this.choosenBrand.viewValue
      }];
      this.dataService.addTicketData(ticketData).subscribe(res => {
        setTimeout(() => {
          if (res['status'] == 'success') {
            this.ticketId = res['message'];
            this.ticketDataValidation = true;
            this.ticketDataForm.reset();
            this.showProgress = false;
          };
        }, 1100);
      });
    } else {
      if (this.ticketDataForm.get('dataAgreedFormControl').value == false) {
        this.dataPolicyValidation = false;
      }
      if (this.ticketDataValidation == false) {
        this._snackBar.openFromComponent(SnackBarInfoAuthor, {
          duration: 2000
        });
      };
    };
  };
  // if privacy policy checkbox state change
  dataCheckBoxChange() {
    if (this.ticketDataForm.get('dataAgreedFormControl').value == false) {
      this.dataPolicyValidation = false;
    } else {
      this.dataPolicyValidation = true;
      this._snackBar.dismiss();
    }
  }

  openDialog() {
    if (this.company !== 'iww') {
      this.dialog.open(DialogDataPolicyAuthor);
    } else {
      this.dialog.open(DialogDataPolicyAuthorIWW);
    }
  }
}
// data policy dialog
@Component({
  selector: 'data-policy-dialog',
  templateUrl: './../data-policy-dialog.html'
})
export class DialogDataPolicyAuthor { }
// data policy dialog
@Component({
  selector: 'data-policy-dialog',
  templateUrl: './../data-policy-dialog_iww.html'
})
export class DialogDataPolicyAuthorIWW { }
// snack bar
@Component({
  selector: 'snack-bar-info',
  templateUrl: './../snack-bar-info.html'
})
export class SnackBarInfoAuthor { }