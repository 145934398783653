<!-- toolbar -->
<div class="toolbar" role="banner">
    <span class="spacer-1"></span>
    <!-- language select -->
    <mat-form-field class="language-select">
        <div class="language-align">
            <mat-icon class="fi fi-{{param.lang == 'en' ? 'us' : param.lang}}"></mat-icon>
            <mat-select (ngModelChange)="translate.use(param.lang); goto(param.lang)" [ngModel]="param.lang" [(value)]="param.lang">
                <mat-option *ngIf="param.lang === 'en'" value="en"><span class="fi fi-us" style="margin-right: 8px;"></span>English</mat-option>
                <mat-option *ngIf="param.lang === 'de'" value="en"><span class="fi fi-us" style="margin-right: 8px;"></span>Englisch</mat-option>
                <mat-option *ngIf="param.lang === 'fr'" value="en"><span class="fi fi-us" style="margin-right: 8px;"></span>Anglaise</mat-option>

                <mat-option *ngIf="param.lang === 'en'" value="de"><span class="fi fi-de" style="margin-right: 8px;"></span>German</mat-option>
                <mat-option *ngIf="param.lang === 'de'" value="de"><span class="fi fi-de" style="margin-right: 8px;"></span>Deutsch</mat-option>
                <mat-option *ngIf="param.lang === 'fr'" value="de"><span class="fi fi-de" style="margin-right: 8px;"></span>Allemande</mat-option>

                <mat-option *ngIf="param.lang === 'en'" value="fr"><span class="fi fi-fr" style="margin-right: 8px;"></span>French</mat-option>
                <mat-option *ngIf="param.lang === 'de'" value="fr"><span class="fi fi-fr" style="margin-right: 8px;"></span>Französisch</mat-option>
                <mat-option *ngIf="param.lang === 'fr'" value="fr"><span class="fi fi-fr" style="margin-right: 8px;"></span>Française</mat-option>
            </mat-select>
        </div>
    </mat-form-field>
</div>