import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthorComponent } from './author/author.component';
import { BelegheftComponent } from './belegheft/belegheft.component';
import { ContentComponent } from './content/content.component';

const routes: Routes = [
  { path: 'author/:company', component: AuthorComponent },
  { path: 'author', component: AuthorComponent },
  { path: 'belegheft/:lang/:email/:brand/:name/:anrede/:ausgabe', component: BelegheftComponent },
  { path: 'belegheft/:lang/:email/:brand/:name/:anrede', component: BelegheftComponent },
  { path: 'belegheft/:lang/:email/:brand/:name', component: BelegheftComponent },
  { path: 'belegheft/:lang/:email/:brand', component: BelegheftComponent },
  { path: 'belegheft/:lang/:email', component: BelegheftComponent },
  { path: 'belegheft/:lang', component: BelegheftComponent },
  { path: 'belegheft', component: BelegheftComponent },
  { path: 'printedcopy/:lang/:email/:brand/:name/:anrede/:ausgabe', component: BelegheftComponent },
  { path: 'printedcopy/:lang/:email/:brand/:name/:anrede', component: BelegheftComponent },
  { path: 'printedcopy/:lang/:email/:brand/:name', component: BelegheftComponent },
  { path: 'printedcopy/:lang/:email/:brand', component: BelegheftComponent },
  { path: 'printedcopy/:lang/:email', component: BelegheftComponent },
  { path: 'printedcopy/:lang', component: BelegheftComponent },
  { path: 'printedcopy', component: BelegheftComponent },
  { path: ':lang/:brand/:requestType/:topic', component: ContentComponent },
  { path: ':lang/:brand/:requestType', component: ContentComponent },
  { path: ':lang/:brand', component: ContentComponent },
  { path: ':lang', component: ContentComponent },
  { path: '**', component: ContentComponent },
  { path: '', component: ContentComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }