<h1 mat-dialog-title>{{ 'DATAPOLICY.TITLE' | translate }}</h1>
<mat-dialog-content class="mat-typography">
  <h2 style="line-height: 1;">{{ 'DATAPOLICY.FIRST_PARAGRAPH_TITLE' | translate }}</h2><br>
  <h4>{{ 'DATAPOLICY.FIRST_PARAGRAPH_TEXT' | translate }}</h4>

  <!--<h2>{{ 'DATAPOLICY.SECOND_PARAGRAPH_TITLE' | translate }}</h2>
  <h4>{{ 'DATAPOLICY.SECOND_PARAGRAPH_TEXT' | translate }}</h4>-->
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button class="button-styles" [mat-dialog-close]="true">{{ 'DATAPOLICY.CLOSE_BUTTON' | translate }}</button>
</mat-dialog-actions>