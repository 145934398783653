import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ContentComponent, DialogDataPolicy, DialogDataPolicyIWW, DialogDataPolicyWIN, SnackBarInfo } from './content/content.component';
import { AuthorComponent, DialogDataPolicyAuthor, DialogDataPolicyAuthorIWW, SnackBarInfoAuthor } from './author/author.component';
import { BelegheftComponent, DialogDataPolicyBelegheft, SnackBarInfoBelegheft } from './belegheft/belegheft.component';
import { ToolbarComponent } from './partials/toolbar/toolbar.component';

import { MatTabsModule } from '@angular/material/tabs';
import { MatSliderModule } from '@angular/material/slider';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { MatExpansionModule } from '@angular/material/expansion';

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

@NgModule({ declarations: [
        ContentComponent,
        AuthorComponent,
        BelegheftComponent,
        ToolbarComponent,
        AppComponent,
        DialogDataPolicy,
        DialogDataPolicyWIN,
        SnackBarInfo,
        DialogDataPolicyAuthor,
        DialogDataPolicyAuthorIWW,
        DialogDataPolicyIWW,
        SnackBarInfoAuthor,
        DialogDataPolicyBelegheft,
        SnackBarInfoBelegheft
    ],
    bootstrap: [AppComponent, AuthorComponent, BelegheftComponent],
    exports: [TranslateModule], imports: [BrowserAnimationsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        FormsModule,
        ReactiveFormsModule,
        MatSliderModule,
        MatIconModule,
        MatSelectModule,
        MatInputModule,
        MatFormFieldModule,
        MatButtonModule,
        MatCheckboxModule,
        MatDialogModule,
        MatProgressBarModule,
        MatSnackBarModule,
        MatTabsModule,
        MatGridListModule,
        MatSlideToggleModule,
        MatCardModule,
        MatDividerModule,
        MatListModule,
        MatExpansionModule,
        AppRoutingModule], providers: [provideHttpClient(withInterceptorsFromDi())] })
export class AppModule { }