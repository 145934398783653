<mat-progress-bar *ngIf="showProgress == true" mode="indeterminate"></mat-progress-bar>

<div class="logo-align">
    <a *ngIf="company !== 'iww'" class="logo-element" href="https://vogel.de/" target="_blank" rel="noreferrer"><img
            class="logo" alt="Logo" src="https://cdn4.vogel.de/logos/1009/logo.svg" /></a>
    <a *ngIf="company === 'iww'" class="logo-element" href="https://iww.de/" target="_blank" rel="noreferrer"><img
            class="logo" alt="Logo" src="https://www.iww.de/img/logo_iww.svg" /></a>
</div>

<!-- content -->
<div *ngIf="ticketDataValidation == false" class="content" role="main">

    <div class="mobile-content">
        <p>Sie sind Autor:in im Auftrag einer Medienmarke
            <ng-container *ngIf="company !== 'iww'">der Vogel Communications Group</ng-container>
            <ng-container *ngIf="company === 'iww'">des IWW Institut für Wissen in der Wirtschaft</ng-container>
            und haben einen Rahmenvertrag mit uns. Dann können Sie sich als Urheber über dieses Formular eine Auswertung
            über den Umfang Ihrer Werknutzung per Mail zukommen lassen.
        </p>
    </div>

    <!-- form -->
    <form [formGroup]="ticketDataForm" (ngSubmit)="submitTicketData($event)" class="form">

        <div>
            <!-- email input field -->
            <mat-form-field appearance="fill" style="margin-top: 20px;">
                <mat-label>{{ 'FORM.EMAIL' | translate }}</mat-label>
                <input maxlength="64" required matInput formControlName="emailFormControl">
                <mat-hint align="start">{{ 'FORM.EXAMPLE' | translate }}: max.mustermann&#64;example.com</mat-hint>
                <mat-error *ngIf="ticketDataForm.controls.emailFormControl.errors?.email">
                    {{ 'FORM.REQUIREDVALIDEMAIL' | translate }}
                </mat-error>
                <mat-error *ngIf="ticketDataForm.controls.emailFormControl.errors?.required">
                    {{ 'FORM.REQUIREDEMAIL' | translate }}
                </mat-error>
            </mat-form-field>

            <!-- gender input field -->
            <mat-form-field appearance="fill">
                <mat-label>{{ 'FORM.GENDER' | translate }}</mat-label>
                <mat-select required formControlName="genderFormControl">
                    <mat-option class="mat-select-pinny" value="Weiblich">Weiblich</mat-option>
                    <mat-option class="mat-select-pinny" value="Männlich">Männlich</mat-option>
                    <mat-option class="mat-select-pinny" value="Divers">Divers</mat-option>
                </mat-select>

                <mat-hint align="start">{{ 'FORM.GENDERHINT' | translate }}</mat-hint>
                <mat-error *ngIf="ticketDataForm.controls.genderFormControl.errors?.required">
                    {{ 'FORM.REQUIREDGENDER' | translate }}
                </mat-error>
            </mat-form-field>

            <!-- title select -->
            <mat-form-field appearance="fill">
                <mat-label>{{ 'FORM.TITLE' | translate }}</mat-label>
                <mat-select formControlName="titleTypeControl">
                    <mat-option class="mat-select-pinny" *ngFor="let title of titles" [value]="title.viewValue">
                        {{ title.viewValue }}
                    </mat-option>
                </mat-select>
                <mat-hint align="start">{{ 'FORM.TITLEHINT' | translate }}</mat-hint>
            </mat-form-field>

            <!-- fullname input field -->
            <mat-form-field appearance="fill">
                <mat-label>{{ 'FORM.FULLNAME' | translate }}</mat-label>
                <input maxlength="64" required matInput formControlName="fullNameFormControl">
                <mat-hint align="start">{{ 'FORM.EXAMPLE' | translate }}: Max Muster</mat-hint>
                <mat-error *ngIf="ticketDataForm.controls.fullNameFormControl.errors?.required">
                    {{ 'FORM.REQUIREDFULLNAME' | translate }}
                </mat-error>
            </mat-form-field>

            <!-- details input field-->
            <mat-form-field appearance="fill">
                <mat-label>Anmerkung</mat-label>
                <textarea cdkTextareaAutosize cdkAutosizeMinRows="6" matInput maxlength="32765"
                    formControlName="detailsFormControl"></textarea>
                <mat-hint align="start">Wenn Sie uns etwas mitteilen möchten.</mat-hint>
            </mat-form-field>
        </div>

        <div>
            <!-- data privacy policy text -->
            <p [ngStyle]="{'padding': '8px 0' }">
                {{ 'FORM.DATAPROTECTION_1' | translate }} <a (click)="openDialog()">{{ 'FORM.DATAPROTECTION_2' |
                    translate }}</a>
                {{ 'FORM.DATAPROTECTION_3' | translate }}
            </p>

            <!-- agree with data policy checkbox -->
            <mat-checkbox (change)="dataCheckBoxChange()" formControlName="dataAgreedFormControl" class="data-checkbox"
                required>
                {{ 'FORM.AGREED' | translate }} *
            </mat-checkbox>
            <mat-error *ngIf="dataPolicyValidation == false">
                {{ 'FORM.REQUIREDDATAAGREEMENT' | translate }}
            </mat-error><br>

            <!-- button 'send' at the bottom of the form -->
            <div class="button-position">
                <button>{{ 'FORM.SEND' | translate }}</button>
            </div>

            <p style="padding-top: 14px;font-size: 14px">* {{ 'FORM.REQUIREDFIELDS' | translate }}</p>
        </div>
    </form>
    <!-- footer vcg -->
    <div *ngIf="company !== 'iww'" [ngStyle]="{'position' : 'relative' }" class="footer" role="footer">
        <a href="https://www.vogel.de/impressum/" target="_blank" rel="noreferrer">{{ 'FOOTER.IMPRINT' | translate
            }}</a> | <a href="https://privacy.vogel.de/datenschutz.html" target="_blank" rel="noreferrer">{{
            'DATAPOLICY.TITLE' | translate }}</a><br>
        Copyright {{ year }} <a href="https://www.vogel.de/" target="_blank" rel="noreferrer">Vogel Communications
            Group</a> GmbH &amp; Co. KG
    </div>
    <!-- footer iww -->
    <div *ngIf="company === 'iww'" [ngStyle]="{'position' : 'relative' }" class="footer" role="footer">
        <a href="https://www.iww.de/impressum/" target="_blank" rel="noreferrer">{{ 'FOOTER.IMPRINT' | translate }}</a>
        | <a href="https://iww.de/datenschutz" target="_blank" rel="noreferrer">{{ 'DATAPOLICY.TITLE' | translate
            }}</a><br>
        Copyright {{ year }} <a href="https://www.iww.de/" target="_blank" rel="noreferrer">IWW Institut für Wissen in
            der Wirtschaft</a> GmbH
    </div>
</div>

<!-- text if ticket successful submitted -->
<div class="content ticket-submitted-text" role="main" *ngIf="ticketDataValidation == true">
    <ng-container *ngIf="company!=='iww'">
        <h1 [ngStyle]="{'color': '#2289f7' }">{{ 'FORM.WE_GOT_IT' | translate }}</h1>
        <h3 style="padding: 24px 0">{{ 'FORM.SERVICENUMBER' | translate }} <b>{{ ticketId }}</b><br><br>
        <p>{{ 'FORM.WE_CHECK_IT_WITHIN_TWO_DAYS' | translate }} </p><br>
        <p>{{ 'FORM.BESTREGARDS' | translate }},</p>
        <p>{{ 'FORM.SUPPORTTEAM' | translate}}</p>
        </h3>
    </ng-container>

    <ng-container *ngIf="company==='iww'">
        <h1 [ngStyle]="{'color': '#00305e' }">Vielen Dank. Ihre Anfrage haben wir erhalten.</h1>
        <h3 style="padding: 24px 0">{{ 'FORM.SERVICENUMBER' | translate }} <b>{{ ticketId }}</b></h3>
        <p>Wir erstellen zeitnah die von Ihnen gewünschte Übersicht. Diese wird in der Regel</p>
        <p>innerhalb von 8 Tagen per E-Mail bei Ihnen eingehen.</p>
        <p>Bitte geben Sie bei Nachfragen immer die oben genannte Servicenummer mit an.</p>
        <p>Damit erleichtern Sie uns unsere Arbeit ungemein. Dafür danken wir Ihnen.</p>

        <div style="padding: 24px 0">
            <p>Mit freundlichen Grüßen</p>
            <p><b>Ihr Team Organisation IWW Autorenabrechnung</b></p>
            <p>Bettina  Kürfgen & Beate Gerenkamp</p>
            <p>Tel. 02596 922-39 | Tel. 02596 922-11</p>
        </div>

        <div style="padding: 24px 0">
            <p>IWW Institut für Wissen in der Wirtschaft GmbH</p>
            <p>Niederlassung: Aspastraße 24, 59394 Nordkirchen</p>
            <p>Telefon: 02596 922-0 | E-Mail: <a href="mailto:Autoren@iww.de">Autoren&#64;iww.de</a> | <a href="https://www.iww.de/" target="_blank">https://www.iww.de</a></p>
        </div>
    </ng-container>

    <!-- footer -->
    <div *ngIf="company !== 'iww'" [ngStyle]="{'position' : 'absolute' }" class="footer" role="footer">
        <a href="https://www.vogel.de/impressum/" target="_blank" rel="noreferrer">{{ 'FOOTER.IMPRINT' | translate
            }}</a> | <a href="https://privacy.vogel.de/datenschutz.html" target="_blank" rel="noreferrer">{{
            'DATAPOLICY.TITLE' | translate }}</a><br>
        Copyright {{ year }} <a href="https://www.vogel.de/" target="_blank" rel="noreferrer">Vogel Communications
            Group</a> GmbH &amp; Co. KG
    </div>

    <!-- footer iww -->
    <div *ngIf="company === 'iww'" [ngStyle]="{'position' : 'absolute' }" class="footer" role="footer">
        <a href="https://www.iww.de/impressum/" target="_blank" rel="noreferrer">{{ 'FOOTER.IMPRINT' | translate }}</a>
        | <a href="https://iww.de/datenschutz" target="_blank" rel="noreferrer">{{ 'DATAPOLICY.TITLE' | translate
            }}</a><br>
        Copyright {{ year }} <a href="https://www.iww.de/" target="_blank" rel="noreferrer">IWW Institut für Wissen in
            der Wirtschaft</a> GmbH
    </div>
</div>