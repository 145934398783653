import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Brand } from './brandData';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class BrandDataService {
  private message;
  private apiUrl = 'https://astro.vogel.de/ssd/brands';
  // http options
  httpOptions = {
    headers: new HttpHeaders(
      {
        'Content-Type': 'application/json'
      })
  };

  constructor(private http: HttpClient) { }
  /** GET: all brands */
  getAllBrands(): Observable<Brand[]>{
    return this.http.get<Brand[]>(this.apiUrl).pipe(
      catchError(this.handleError)
    );
  };
  handleError(error) {
    if (error.error instanceof ErrorEvent) {
      this.message = error.error.message;
    } else {
      this.message = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(this.message);
  };
}