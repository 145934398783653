import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Ticket } from './ticketData';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TicketDataService {
  private dataAgreement;
  private message;
  private requestType;
  private topicZUA;
  private topicIWW;
  private apiUrl = 'https://astro.vogel.de/ssd/ticket';
  // http options
  httpOptions = {
    headers: new HttpHeaders(
      {
        'Content-Type': 'application/json'
      })
  };
  constructor(private http: HttpClient) { }

  /** PUT: add new Ticket to the server */
  addTicketData(ticketData: Ticket): Observable<Ticket> {

    if (ticketData[0].dataAgreement === true) {
      this.dataAgreement = 1;
    } else {
      this.dataAgreement = 0;
    }

    switch (ticketData[0].requestType) {
      case 'aa':
        this.requestType = '11601';
        break;
      case 'belegheft':
        this.requestType = '11602';
        break;
      case 'zua':
        this.requestType = '11884';
        break;
      case 'run':
        this.requestType = '10853';
        break;
      case 'ts':
        this.requestType = '10851';
        break;
      case 'madst':
        this.requestType = '10854';
        break;
      case 'madr':
        this.requestType = '10852';
        break;
      case 'sa':
        this.requestType = '10855';
        break;
      case 'mae':
        this.requestType = '11885';
        break;
      default:
        this.requestType = ticketData[0].requestType;
        break;
    }

    switch (ticketData[0].topicZUA) {
      case 'aah':
        this.topicZUA = 'Abokündigung / Abbestellung Heftversand';
        break;
      case 'a':
        this.topicZUA = 'Adressänderung';
        break;
      case 'ea':
        this.topicZUA = 'Empfänger ausgeschieden';
        break;
      case 'fek':
        this.topicZUA = 'Firma erloschen / in Konkurs';
        break;
    }

    switch (ticketData[0].topicIWW) {
      case 'fa':
        this.topicIWW = 'Fragen zum aktuellen (Probe-)Abonnement';
        break;
      case 'ka':
        this.topicIWW = 'Kündigung/Widerruf meines (Probe-)Abonnements';
        break;
      case 'n':
        this.topicIWW = 'Nachlieferung';
        break;
      case 'a':
        this.topicIWW = 'Adressänderung';
        break;
      case 'frmg':
        this.topicIWW = 'Fragen zur Rechnung, Mahnung, Gutschrift';
        break;
      case 'fb':
        this.topicIWW = 'Fragen zur Bankverbindung';
        break;
      case 'wp':
        this.topicIWW = 'Widerruf des Probeabos';
        break;
      case 'ft':
        this.topicIWW = 'Fragen zur Testphase';
        break;
      case 'ww':
        this.topicIWW = 'Werbewiderruf';
        break;
      case 'ausk':
        this.topicIWW = 'Auskunft gemäß DSGVO';
        break;
      case 'bl':
        this.topicIWW = 'Benutzerkonto löschen';
        break;
      case 'if':
        this.topicIWW = 'Inhaltliche Fragen/Fragen an die Redaktion';
        break;
      case 'tf':
        this.topicIWW = 'Technische Fragen';
        break;
    }
    const tdata = {
      anrede: ticketData[0].anrede, 
      title: ticketData[0].title,
      gender: ticketData[0].gender,
      requestType: this.requestType,
      details: ticketData[0].details,
      email: ticketData[0].email,
      event: ticketData[0].event,
      fullName: ticketData[0].fullName,
      telephone: ticketData[0].telephone,
      dataAgreement: this.dataAgreement,
      brand: ticketData[0].brand,
      lang: ticketData[0].lang,
      job: ticketData[0].job,
      firma: ticketData[0].firma,
      straße: ticketData[0].straße,
      plz: ticketData[0].plz,
      stadt: ticketData[0].stadt,
      land: ticketData[0].land,
      ausgabe: ticketData[0].ausgabe,
      menge: ticketData[0].menge,
      angebot: ticketData[0].angebot,
      company: ticketData[0].company,
      topicZUA: this.topicZUA,
      abonummer: ticketData[0].abonummer,
      empfaenger: ticketData[0].empfaenger,
      topicIWW: this.topicIWW,
      address: ticketData[0].address,
      newAddress: ticketData[0].newAddress,
      oldAddress: ticketData[0].oldAddress,
      url: ticketData[0].url,
      rechnungMahnung: ticketData[0].rechnungMahnung,
      informationsdienst: ticketData[0].informationsdienst
    };

    return this.http.put<Ticket>(this.apiUrl, JSON.stringify(tdata), this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  };
  handleError(error) {
    if (error.error instanceof ErrorEvent) {
      this.message = error.error.message;
    } else {
      this.message = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(this.message);
  };
}
